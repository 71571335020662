import React from 'react'
import cateringOptions from '../../data/catering.json'
import Field from '../Field'
import NumberedFields from '../NumberedFields'

const LIMITS = {
	MEATS: 3,
	SIDES: 5,
	APPETIZERS: 3,
	DESSERTS: 2,
}

const NONE_OPTION = '-- None --'

class CateringOptionsForm extends React.Component {
	render() {
		function addNone(list) {
			if (list[0] === NONE_OPTION) return list

			list.unshift(NONE_OPTION)
			return list
		}

		return (
			<div>
				<Field
					type="radiogroup"
					label="Service option"
					value={this.props.values.service}
					options={cateringOptions.tiers}
					onChange={this._onUpdateService}
				/>

				<NumberedFields
					label="Meat"
					options={addNone(cateringOptions.meats)}
					values={this.props.values.meats}
					limit={LIMITS.MEATS}
					onChange={this._onUpdateMeats}
				/>

				<NumberedFields
					label="Side"
					options={addNone(cateringOptions.sides)}
					values={this.props.values.sides}
					limit={LIMITS.SIDES}
					onChange={this._onUpdateSides}
				/>

				<NumberedFields
					label="Appetizer"
					options={addNone(cateringOptions.appetizers)}
					values={this.props.values.appetizers}
					limit={LIMITS.APPETIZERS}
					onChange={this._onUpdateAppetizers}
				/>

				<NumberedFields
					label="Dessert"
					options={addNone(cateringOptions.desserts)}
					values={this.props.values.desserts}
					limit={LIMITS.DESSERTS}
					onChange={this._onUpdateDesserts}
				/>

				<Field
					label="Special Instructions?"
					type="longstring"
					values={this.props.values.special_instructions}
					onChange={this._onUpdateSpecialInstructions}
				/>
			</div>
		)
	}

	_onUpdateService = value => {
		this._setValue('service', value)
	}

	_onUpdateMeats = value => {
		this._setValue('meats', value)
	}

	_onUpdateAppetizers = value => {
		this._setValue('appetizers', value)
	}

	_onUpdateSides = value => {
		this._setValue('sides', value)
	}

	_onUpdateDesserts = value => {
		this._setValue('desserts', value)
	}

	_onUpdateSpecialInstructions = value => {
		this._setValue('special_instructions', value)
	}

	_setValue(field, value) {
		this.props.onChange(field, value)
	}
}

export default CateringOptionsForm
