import React from 'react'
import String from './String'
import LongString from './LongString'
import MultipleChoice from './MultipleChoice'
import RadioGroup from './RadioGroup'

const Field = ({ value, type, label, ...props }) => {
	let field = <String value={value} {...props} />
	if (type === 'longstring') field = <LongString value={value} {...props} />
	else if (type === 'multiplechoice')
		field = <MultipleChoice value={value} {...props} />
	else if (type === 'radiogroup') {
		return (
			<legend>
				{label}
				<RadioGroup name={label} value={value} {...props} />
			</legend>
		)
	}

	return (
		<label>
			{label}
			{field}
		</label>
	)
}

export default Field
