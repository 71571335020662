import React from 'react'

const RadioGroup = ({ name, value, onChange, options }) => (
	<fieldset>
		<legend className="sr-only">{name}</legend>
		{options.map(option => {
			const val = option && option.label ? option.label : option

			return (
				<div key={val}>
					<label>
						<input
							type="radio"
							name={name}
							value={val}
							onChange={evt => onChange(evt.target.value)}
						/>
						{val}
					</label>
				</div>
			)
		})}
	</fieldset>
)

export default RadioGroup
