import React, { useRef, useEffect } from 'react'

const MultipleChoice = ({ value, onChange, options, autofocus }) => {
	let inputEl = useRef(null)

	useEffect(() => {
		if (autofocus) inputEl.current.focus()
	}, [autofocus])

	return (
		<select
			ref={inputEl}
			value={value}
			onChange={evt => onChange(evt.target.value)}
		>
			{options.map(option => (
				<option key={option}>{option}</option>
			))}
		</select>
	)
}

export default MultipleChoice
