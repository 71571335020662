import React from 'react'
import Field from '../Field'

import './styles.css'
import { LiveMessage } from 'react-aria-live'

class NumberedFields extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			num: 1,
		}
	}

	render() {
		var addNext = this.state.num < this.props.limit && (
			<button
				onClick={this._addNew}
				type="button"
				className="gray-button add-another"
			>
				<span role="presentation" aria-hidden="true">
					+{' '}
				</span>
				Add another {this.props.label.toLowerCase()}
			</button>
		)

		let fields = []
		for (let i = 0; i < this.state.num; i++) {
			fields.push(
				<div key={i}>
					<Field
						type="multiplechoice"
						label={this.props.label + ' ' + (i + 1)}
						options={this.props.options}
						value={this.props.values && this.props.values[i]}
						onChange={val => {
							this._onChange(i, val)
						}}
						autofocus={i > 0}
					/>
				</div>
			)

			if (i > 0) {
				fields.push(
					<LiveMessage message="choice added" aria-live="assertive" />
				)
			}
		}

		return (
			<div className="numbered-fields-group">
				{fields}
				{addNext}
			</div>
		)
	}

	_onChange = (index, value) => {
		let existingValues = this.props.values
		if (!existingValues) existingValues = []

		existingValues[index] = value

		this.props.onChange(existingValues)
	}

	_addNew = () => {
		if (this.state.num + 1 > this.props.limit) return

		this.setState({
			num: this.state.num + 1,
		})
	}
}

export default NumberedFields
