import React from 'react';
import { sections } from '../data/cateringForm.json';
import AntiSpam from '../components/AntiSpam';
import CateringOptionsForm from '../components/CateringOptionsForm';
import { navigate } from 'gatsby';
import Layout from '../layouts/layout.js';

class CateringRequest extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {}
		};
	}

	render() {
		return (
			<Layout title="Catering Request">
				<div>
					<h1>Catering Request</h1>

					<form onSubmit={this._submitForm}>
						{sections.map((section, index) => (
							<div
								className="catering-request-section"
								key={index}
							>
								<section>
									<h2>{section.title}</h2>
									{section.fields.map((field, index) => (
										<div key={index}>
											<label>
												{field.label +
													(field.required
														? ' *'
														: '')}
												<input
													type={field.type || 'text'}
													name={field.name}
													required={field.required}
													pattern={field.pattern}
													title={field.title}
													onChange={evt =>
														this._setField(
															field.name,
															evt.target.value
														)
													}
												/>
											</label>
										</div>
									))}
								</section>
							</div>
						))}

						<CateringOptionsForm
							values={this.state.fields}
							onChange={(name, value) =>
								this._setField(name, value)
							}
						/>

						<AntiSpam
							value={this.state.spamcheck}
							onChange={this._markAntiSpam.bind(this)}
						/>

						<button type="submit" className="submit pink-button">
							Submit Request
						</button>
					</form>
				</div>
			</Layout>
		);
	}

	_setField = (name, value) => {
		this.setState(prevState => {
			const fields = prevState.fields;
			fields[name] = value;
			return {
				fields
			};
		});
	};

	_markAntiSpam = value => {
		this.setValue({
			antiSpamPass: value
		});
	};

	_submitForm = evt => {
		fetch('/api/catering-request?email=' + this.state.fields.email, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			method: 'POST',
			body: JSON.stringify(this.state.fields)
		})
			.then(function(res) {
				return res.json();
			})
			.then(data => {
				const wasSuccessful = data && data.success;

				this.setState({
					sendSuccess: wasSuccessful
				});

				if (wasSuccessful) navigate('/catering-request-sent');
			});

		evt.preventDefault();
		return false;
	};
}

export default CateringRequest;
